import React from "react";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Helmet from 'react-helmet';
import 'react-day-picker/lib/style.css';
import { ControlGroup } from "@blueprintjs/core";

type State = {
    date: Date
    keyword: string
}

type Props = {
    onSearch: (date: Date, Keyword: string) => void
}

export class Control extends React.Component<Props, State> {

    constructor(props) {
        super(props)

        this.state = {
            date: new Date(),
            keyword: ""
        };
    }

    onDayChange = (date) => {
        this.setState({
            date: date
        })
    }

    onKeywordChange = (input) => {
        var value = input.target.value
        this.setState({
            keyword: value
        })
    }

    onSearch = () => {
        this.props.onSearch(
            this.state.date,
            this.state.keyword
        )
    }

    render() {
        let st = {
            fontSize: "14px",
            height: "30px",
            lineHeight: "30px",
            border: "none",
        }

        return (
            <div>
                <Helmet>
                    <style>{`
                            .DayPickerInput-Overlay {
                                z-index: 99;
                            }
                            .DayPicker-Day--birthday {
                                background-color: #00bcd4;
                                color: white;
                            }
                            .DayPicker-Day--monday {
                                color: #00bcd4;
                            }
                            .DayPickerInput input {
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                background: #fff;
                                border: none;
                                border-radius: 3px;
                                -webkit-box-shadow: 0 0 0 0 rgb(19 124 189 / 0%), 0 0 0 0 rgb(19 124 189 / 0%), inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 20%);
                                box-shadow: 0 0 0 0 rgb(19 124 189 / 0%), 0 0 0 0 rgb(19 124 189 / 0%), inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 20%);
                                color: #182026;
                                font-size: 14px;
                                font-weight: 400;
                                height: 30px;
                                line-height: 30px;
                                outline: none;
                                padding: 0 10px;
                                -webkit-transition: -webkit-box-shadow .1s cubic-bezier(.4,1,.75,.9);
                                transition: -webkit-box-shadow .1s cubic-bezier(.4,1,.75,.9);
                                transition: box-shadow .1s cubic-bezier(.4,1,.75,.9);
                                transition: box-shadow .1s cubic-bezier(.4,1,.75,.9),-webkit-box-shadow .1s cubic-bezier(.4,1,.75,.9);
                                vertical-align: middle;
                            }
                    `}</style>
                </Helmet>

                <ControlGroup>
                    <label className="bp3-label bp3-inline" style={{ marginTop: "-1px" }}>
                        Date
                        <span style={{ marginLeft: "5px" }}>
                            <DayPickerInput style={st}
                                onDayChange={this.onDayChange}
                                value={this.state.date} />
                        </span>
                    </label>
                    <label className="bp3-label bp3-inline">
                        <ControlGroup>
                            <input placeholder="Keyword" className="bp3-input bp3-inline"
                                onChange={this.onKeywordChange} />
                            <button className="bp3-button" onClick={this.onSearch}>Search</button>
                        </ControlGroup>
                    </label>
                </ControlGroup>

            </div >
        )
    }
}