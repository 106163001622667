import { Button, ButtonGroup } from "@blueprintjs/core"
import React from "react"
import { ReportClient, ReportQuery, ReportRespose } from "../api/ApiClient"
import { Control } from "./Control"

type Props = {}
type State = {
    date: Date
    pageIndex: number,
    info: ReportRespose
}

export class Report extends React.Component<Props, State> {

    api = new ReportClient()

    constructor(props) {
        super(props)

        this.state = {
            date: new Date(),
            pageIndex: 0,
            info: new ReportRespose()
        }

        var query = new ReportQuery()
        query.date = this.state.date;

        this.startQuery(query)
    }

    isPageActive = (index: number) => {
        return this.state.pageIndex == index;
    }

    onPageChange = (page: number) => {
        this.setState({
            pageIndex: page
        }, () => {
            this.reload();
        })
    }

    startQuery(query: ReportQuery) {
        this.api.query(query).then(info => {
            this.setState({
                info: info
            })
        })
    }

    reload = () => {
        let query = new ReportQuery()
        query.page = this.state.pageIndex
        this.startQuery(query);
    }

    onSeach = (date: Date, keyword: string) => {
        var query = new ReportQuery()
        query.date = date
        query.keyworkd = keyword
        this.startQuery(query)
    }

    render() {
        return (
            <div className="report">

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Control onSearch={this.onSeach} />
                </div>

                <table style={{ width: "100%", zIndex: 0 }} className="bp3-html-table pb3-small bp3-html-table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th> Uuid </th>
                            <th>Path</th>
                            <th>Create</th>
                            <th>Upload</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.info?.items?.map((x, i) =>
                                <tr>
                                    <td>{this.state.info.pageIndex * this.state.info.pageSize + (i + 1)}</td>
                                    <td>
                                        <a href={x.link} target="_blank">
                                            {x.uuid}
                                        </a>
                                    </td>
                                    <td>
                                        {x.path}/{x.name}
                                    </td>
                                    <td>
                                        {x.createDate}
                                    </td>
                                    <td>
                                        {x.uploadDate}
                                    </td>
                                    <td>
                                        <span className="bp3-tag bp3-round">
                                            {x.status}
                                        </span>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>

                {
                    this.state.info?.items?.length ?
                        (
                            <div style={{ textAlign: "center", width: "100%", margin: "20px" }}>
                                <ButtonGroup minimal={true} >
                                    {[...Array(this.state.info?.numberOfPages).keys()].map(i =>
                                        <Button active={this.isPageActive(i)} onClick={x => this.onPageChange(i)}>{i + 1}</Button>
                                    )}
                                </ButtonGroup>
                            </div>
                        )
                        : <></>
                }
            </div >
        )
    }
}