export function isLogin() {
    return true;

    var x = localStorage.getItem("tni")
    if (x) {
        return true;
    } else {
        return false;
    }
}

export function login(state) {
    localStorage.setItem("tni", state)
}

export function logout() {
    localStorage.removeItem("tni")
}