import { Alignment, Button, Navbar } from "@blueprintjs/core";
import React from "react"
import { RouteComponentProps } from "react-router";
import { isLogin, logout } from "../login/Functions";

type State = {
    page: string
}

export class Nav extends React.Component<RouteComponentProps<{}>, State> {
    constructor(prop) {
        super(prop)
        this.state = {
            page: "/"
        }
    }

    go = (page) => {
        this.setState({
            page: page
        }, () => {
            this.props.history.push(page);
        })
    }

    onLogin = () => {
        logout()
        this.go("/login")
    }

    render() {
        return (
            isLogin()
                ?
                <Navbar className="bp3-dark">
                    <Navbar.Group align={Alignment.LEFT} >
                        <Navbar.Heading>Upload Manager</Navbar.Heading>
                        <Navbar.Divider />
                        <Button active={this.state.page == "/"} className="bp3-minimal" icon="document" text="Report" onClick={x => this.go("/")} />
                    </Navbar.Group >
                    <Navbar.Group align={Alignment.RIGHT}>
                        <Button active={this.state.page == "/login"} className="bp3-minimal" icon="user" text="" onClick={this.onLogin} />
                    </Navbar.Group>
                </Navbar>
                :
                <Navbar className="bp3-dark">
                    <Navbar.Group align={Alignment.LEFT} >
                        <Navbar.Heading>Upload Manager</Navbar.Heading>
                        <Navbar.Divider />
                        {/* <Button active={this.state.page == "/"} className="bp3-minimal" icon="document" text="Report" onClick={x => this.go("/")} /> */}
                    </Navbar.Group >
                    <Navbar.Group align={Alignment.RIGHT}>
                        <Button active={this.state.page == "/login"} className="bp3-minimal" icon="user" text="" onClick={this.onLogin} />
                    </Navbar.Group>
                </Navbar>
        )
    }
}