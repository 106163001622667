import React from "react"
import ReactDOM from "react-dom"
import { Report } from "./report/Report"
import { HashRouter, Route, Switch } from "react-router-dom";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "./css/style.css"
import { Nav } from "./nav/Nav";

class App extends React.Component {
    render() {
        return (
            <div className="app">
                <HashRouter>
                    <Route component={Nav} />
                    <div>
                        <Switch>
                            <div className="ui segment">
                                <Route exact path='/' component={Report} />
                                {/* <Route exact path='/login' component={Login} /> */}
                            </div>
                        </Switch>
                    </div>
                </HashRouter>
                {/* <div className="footer">
                    <p></p>
                </div> */}
            </div>
        )
    }
}

var el = document.getElementById("root")
ReactDOM.render(<App />, el)